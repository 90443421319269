exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-thank-you-js": () => import("./../../../src/pages/en/thank-you.js" /* webpackChunkName: "component---src-pages-en-thank-you-js" */),
  "component---src-pages-podziekowanie-js": () => import("./../../../src/pages/podziekowanie.js" /* webpackChunkName: "component---src-pages-podziekowanie-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-hubwork-js": () => import("./../../../src/templates/hubwork.js" /* webpackChunkName: "component---src-templates-hubwork-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-space-js": () => import("./../../../src/templates/space.js" /* webpackChunkName: "component---src-templates-space-js" */)
}

